import { useSelector } from 'reffects-store';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import { color, COLOR_PALETTE } from 'design-system/styles/color';
import {
  oneOf,
  userIsFinanceManagerSelector,
  userIsFinanceSelector,
  userIsSuperadminSelector,
  userIsSupportSelector,
} from '../../../../selectors/user';
import { homeRoute } from '../../../../utils/proppitWebRouter';
import { PATHS } from '../../../constants/routes';
import { BrandLogo } from '../../../../partials/BrandLogo';
import HeaderProfileLink from './HeaderProfileLink';
import { useFeatureFlagSelector } from '../../../../utils/featuresFlags/selectors';
import { ProtectedComponent } from '../../../ProtectedComponent';

const Wrapper = styled.div`
  width: 100%;
  ${color.background(COLOR_PALETTE.NEUTRAL_A00)}
  ${color.text(COLOR_PALETTE.NEUTRAL_A00)}
    padding: 1.2rem 1.5rem 1.2rem 1.5rem;
  border-bottom: 1px solid var(--divider-color);
  display: flex;
  align-items: center;
  min-width: 1200px;

  > * {
    ${spacing.inline(SPACING_SIZE.M)}
  }
`;

const HomeLink = styled(Link).attrs({ to: homeRoute() })`
  text-decoration: none;
`;

const Menu = styled.nav`
  flex: 1;

  ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    padding: 0;

    li {
      ${spacing.inline(SPACING_SIZE.XS)}
      ${spacing.inset(SPACING_SIZE.S)}
            cursor: pointer;
      border-radius: ${SPACING_SIZE.XS};

      &:first-child {
        margin: 0;
      }

      &:hover {
        background-color: var(--gray-color-04);
      }

      a {
        text-decoration: none;
        ${color.text(COLOR_PALETTE.NEUTRAL_BASE)}
      }
    }
  }
`;

function Header() {
  const ff = useFeatureFlagSelector('CARING_CENTRALIZE_PERMISSIONS');
  let showPricingTableLink;
  let showCampaignsLink;
  if (!ff) {
    showCampaignsLink = useSelector(
      oneOf(
        userIsSuperadminSelector,
        userIsSupportSelector,
        userIsFinanceSelector,
        userIsFinanceManagerSelector
      )
    );
    showPricingTableLink = useSelector(userIsSuperadminSelector);
  } else {
    // Remove these variables once FF is removed.
    showCampaignsLink = true;
    showPricingTableLink = true;
  }

  return (
    <Wrapper>
      <HomeLink>
        <BrandLogo />
      </HomeLink>
      <Menu>
        <ul>
          <li>
            <Link to="/admin/create-user" data-tag="Header_create_publisher">
              Create publisher
            </Link>
          </li>
          <li>
            <Link to="/admin/users" data-tag="Header_users_list">
              Users list
            </Link>
          </li>
          <li>
            <Link to={PATHS.AGENCIES} data-tag="Header_agencies">
              Agencies
            </Link>
          </li>
          <li>
            <Link to={PATHS.DEVELOPERS} data-tag="Header_developers">
              Developers
            </Link>
          </li>
          <ProtectedComponent
            action="project-campaigns/list"
            forced={showCampaignsLink}
          >
            <li>
              <Link to={PATHS.CAMPAIGNS} data-tag="Header_campaigns">
                Campaigns
              </Link>
            </li>
          </ProtectedComponent>

          <ProtectedComponent
            action="plans-prices/list"
            forced={showPricingTableLink}
          >
            <li>
              <Link
                to="/admin/subscription-plans-management"
                data-tag="Header_pricing_table"
              >
                Pricing Table
              </Link>
            </li>
          </ProtectedComponent>
        </ul>
      </Menu>
      <HeaderProfileLink />
    </Wrapper>
  );
}

export default Header;
