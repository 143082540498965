import {
  oneOf,
  userIsFinanceManagerSelector,
  userIsFinanceSelector,
  userIsSalesManagerSelector,
  userIsSalesSelector,
  userIsSuperadminSelector,
  userIsSupportSelector,
} from '../../../../../../../selectors/user';
import { getFeatureFlagSelector } from '../../../../../../../utils/featuresFlags/selectors';

export const showChangePaymentMethodSelector = userIsSuperadminSelector;

export function showAssociatePublisherSelector(state) {
  return oneOf(userIsSuperadminSelector, userIsSalesManagerSelector)(state);
}

export function showDisassociatePublisherSelector(state) {
  return oneOf(
    userIsSuperadminSelector,
    userIsSalesManagerSelector,
    userIsSalesSelector,
    userIsSupportSelector
  )(state);
}

export function showSendPropertiesToWasiSelector(state) {
  return oneOf(
    userIsSuperadminSelector,
    userIsSalesManagerSelector,
    userIsSalesSelector,
    userIsSupportSelector
  )(state);
}

export function showCancelSubscriptionRequestSelector(state, agency) {
  const FF = getFeatureFlagSelector(state, 'CARING_CENTRALIZE_PERMISSIONS');
  if (!FF) {
    const withRequiredRole = oneOf(
      userIsSuperadminSelector,
      userIsSalesManagerSelector,
      userIsSupportSelector,
      userIsFinanceSelector,
      userIsFinanceManagerSelector
    )(state);

    return agency.renewalCancelRequestStatus === 'pending' && withRequiredRole;
  }

  return agency.renewalCancelRequestStatus === 'pending';
}

export function showMoveToFreeSelector(state, planType) {
  return checkUserIsSuperadminOrFinanceAndPaidPlan(state, planType);
}

export function showMarkAsFreeFromBeginningSelector(state, planType) {
  return checkUserIsSuperadminOrFinanceAndPaidPlan(state, planType);
}

function checkUserIsSuperadminOrFinanceAndPaidPlan(state, planType) {
  return (
    (userIsSuperadminSelector(state) || userIsFinanceSelector(state)) &&
    planType === 'paid'
  );
}

export function showMarkAsTestSelector(state, agency) {
  return userIsSuperadminSelector(state) && !agency.isForTesting;
}
