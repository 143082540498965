import { withoutEventPropagation } from 'design-system/utils/withoutEventPropagation';
import { useCallback } from 'react';
import { dispatch } from 'reffects';
import Table from 'design-system/components/Table';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Tooltip from 'design-system/components/Tooltip';
import Menu from 'design-system/components/Menu/Menu';
import { FlatButton } from 'design-system/components/Button/presets';
import Icon from 'design-system/components/Icon';
import { useSelector } from 'reffects-store';
import { useColumn } from '../../../../partials/DataTable/hooks/useColumn';
import { EDIT_CAMPAIGN_MODAL } from '../constants';
import {
  CAMPAIGN_SELECTED,
  FINISH_CAMPAIGN,
  RENEW_CAMPAIGN_MODAL_OPENED,
} from '../events';
import { OPEN_DIALOG } from '../../../../events/dialogs';
import { GENERIC_CONFIRMATION_MODAL } from '../../../../partials/modals/generic/constants';
import useUpdateTableEvent from '../../../../partials/DataTable/hooks/useUpdateTableEvent';
import { ProtectedComponent } from '../../../ProtectedComponent';
import { userIsSuperadminSelector } from '../../../../selectors/user';
import { useFeatureFlagSelector } from '../../../../utils/featuresFlags/selectors';

export function ActionsColumn(props) {
  const {
    value: { campaignId, status },
  } = useColumn(props);
  const CARING_CENTRALIZE_PERMISSIONS = useFeatureFlagSelector(
    'CARING_CENTRALIZE_PERMISSIONS'
  );
  const updateTableEvent = useUpdateTableEvent();
  const canEditCampaign = useSelector(userIsSuperadminSelector);
  const onRenewCampaign = useCallback(
    () =>
      dispatch({
        id: RENEW_CAMPAIGN_MODAL_OPENED,
        payload: { id: campaignId },
      }),
    [campaignId]
  );
  const onEditCampaign = useCallback(
    () =>
      dispatch({
        id: CAMPAIGN_SELECTED,
        payload: { id: campaignId, modal: EDIT_CAMPAIGN_MODAL },
      }),
    [campaignId]
  );
  const onFinishCampaign = useCallback(
    () =>
      dispatch({
        id: OPEN_DIALOG,
        payload: {
          id: GENERIC_CONFIRMATION_MODAL,
          parameters: {
            messages: {
              title: { id: 'Finish campaign' },
              body: {
                id: 'Are you sure that you want to finish this campaign?',
              },
              confirmButton: { id: 'Yes, finish' },
            },
            actions: {
              confirm: {
                id: FINISH_CAMPAIGN,
                payload: { campaignId, onSuccessEvent: updateTableEvent },
              },
            },
          },
        },
      }),
    [campaignId]
  );

  if (CARING_CENTRALIZE_PERMISSIONS) {
    return (
      <Table.Cell>
        <DropdownMenu
          tooltip={<Tooltip title="Actions" />}
          button={<FlatButton icon={<Icon glyph="moreVert" />} />}
        >
          <Menu>
            <Menu.Item
              label="Renew campaign"
              onClick={withoutEventPropagation(onRenewCampaign)}
            />
            <ProtectedComponent action="project-campaign/edit">
              <Menu.Item
                label="Edit campaign"
                onClick={withoutEventPropagation(onEditCampaign)}
              />
            </ProtectedComponent>

            {status !== 'finished' && (
              <Menu.Item
                label="Finish campaign"
                onClick={withoutEventPropagation(onFinishCampaign)}
              />
            )}
          </Menu>
        </DropdownMenu>
      </Table.Cell>
    );
  }

  return (
    <Table.Cell>
      <DropdownMenu
        tooltip={<Tooltip title="Actions" />}
        button={<FlatButton icon={<Icon glyph="moreVert" />} />}
      >
        <Menu>
          <Menu.Item
            label="Renew campaign"
            onClick={withoutEventPropagation(onRenewCampaign)}
          />
          {canEditCampaign && (
            <Menu.Item
              label="Edit campaign"
              onClick={withoutEventPropagation(onEditCampaign)}
            />
          )}
          {status !== 'finished' && (
            <Menu.Item
              label="Finish campaign"
              onClick={withoutEventPropagation(onFinishCampaign)}
            />
          )}
        </Menu>
      </DropdownMenu>
    </Table.Cell>
  );
}
