import { effects, registerEventHandler } from 'reffects';
import { state } from 'reffects-store';
import { arrayMove } from '@dnd-kit/sortable';
import { http } from 'reffects-batteries';
import { optimizeImages } from '../../../../effects/optimizeImages';
import {
  appendNewImages,
  assignMainImageWhenUndefined,
  createAiAdImages,
  createImage,
  removeImageByUrl,
} from './adImages';
import { postMultipart } from '../../../../effects/http';
import { environment } from '../../../../coeffects/environment';
import { prepareImagesToUpload } from '../../prepareDataToSubmit';
import { tracking } from '../../../../effects/tracking';
import { UPLOAD_ANIMATION_STATUS } from '../../AdForm/partials/UploadProgress/constants';
import { fieldHasMainImage, fieldMaxNumberOfImages } from './utils';

export const IMAGES_SELECTED = 'IMAGES_SELECTED';
export const IMAGES_OPTIMIZED = 'IMAGES_OPTIMIZED';
export const IMAGES_OPTIMIZATION_FAILED = 'IMAGES_OPTIMIZATION_FAILED';
export const IMAGES_UPLOADED = 'IMAGES_UPLOADED';
export const IMAGES_AI_SORTED = 'IMAGES_AI_SORTED';
export const IMAGES_AI_SORTED_FAILED = 'IMAGES_AI_SORTED_FAILED';
export const IMAGES_SORTED = 'IMAGES_SORTED';
export const IMAGE_DELETED = 'IMAGE_DELETED';
export const IMAGE_DELETE_CONFIRMED = 'IMAGE_DELETE_CONFIRMED';
export const IMAGE_DELETE_CANCELED = 'IMAGE_DELETE_CANCELED';
export const IMAGES_PROGRESS_STATUS_UPLOADING =
  'IMAGES_PROGRESS_STATUS_UPLOADING';
export const IMAGES_STATUS_UPLOADED = 'IMAGES_STATUS_UPLOADED';
export const IMAGES_PROGRESS_STATUS_THINKING =
  'IMAGES_PROGRESS_STATUS_THINKING';
export const IMAGES_PROGRESS_STATUS_ONLY_THINKING =
  'IMAGES_PROGRESS_STATUS_ONLY_THINKING';
export const IMAGES_PROGRESS_STATUS_THINKING_FINISH =
  'IMAGES_PROGRESS_STATUS_THINKING_FINISH';
export const IMAGES_PROGRESS_STATUS_ERROR = 'IMAGES_PROGRESS_STATUS_ERROR';
export const IMAGES_STATUS_RESET = 'IMAGES_STATUS_RESET';
export const IMAGES_PROGRESS_STATUS_UPLOAD_FAILED =
  'IMAGES_PROGRESS_STATUS_UPLOAD_FAILED';
export const TOGGLE_SORTING_MODE = 'TOGGLE_SORTING_MODE';
export const RESTORE_SORTING_WITH_AI = 'RESTORE_SORTING_WITH_AI';
export const CHECK_RETRY = 'CHECK_RETRY';
export const RETRY_SORTING_WITH_AI = 'RETRY_SORTING_WITH_AI';
export const IMAGES_PROGRESS_STATUS_SHOW_THINKING =
  'IMAGES_PROGRESS_STATUS_SHOW_THINKING';

const MIN_IMAGES_TO_SORT_WITH_AI = 1;
const RETRY_DELAYS = [10, 20, 40, 60];

registerEventHandler(IMAGES_SELECTED, (_, { field, images, newImages }) => {
  const maxNumberOfImages = fieldMaxNumberOfImages(field);
  if (images.length + newImages.length > maxNumberOfImages) {
    return state.set({
      [`imageUploader.${field}.error`]: {
        id: 'newprop_image_upload_error_limit',
        values: { count: maxNumberOfImages },
      },
    });
  }
  return optimizeImages({
    images: newImages,
    successEvent: {
      id: IMAGES_OPTIMIZED,
      payload: { field, images },
    },
    errorEvent: { id: IMAGES_OPTIMIZATION_FAILED, payload: { field } },
  });
});

registerEventHandler(
  IMAGES_OPTIMIZED,
  (
    { state: { adId, isPremium }, environment: { apiUrl } },
    [optimizedImages, { field, images }]
  ) => {
    if (field !== 'propertyImages') {
      const allImages = mergePreviousImagesWithOptimized(
        images,
        optimizedImages
      );

      return state.set({
        [`adForm:ad.${field}`]: fieldHasMainImage(field)
          ? assignMainImageWhenUndefined(allImages)
          : allImages,
        [`imageUploader.${field}.error`]: undefined,
      });
    }

    const allImages = createOptimizedImagesObjects(images, optimizedImages);
    const [propertyImages, imagesToUpload] = prepareImagesToUpload(allImages);

    return {
      ...postMultipart({
        url: `${apiUrl}/properties/${adId}/images`,
        body: {
          ad: {
            id: adId,
            propertyImages,
            mainImageIndex: 0,
          },
        },
        files: imagesToUpload,
        successEvent: IMAGES_UPLOADED,
        errorEvent: IMAGES_PROGRESS_STATUS_UPLOAD_FAILED,
      }),
      ...state.set({
        [`imageUploader.${field}.error`]: undefined,
      }),
      ...(isPremium && effects.dispatch(IMAGES_PROGRESS_STATUS_UPLOADING)),
    };
  },
  [
    state.get({
      adId: 'adForm:ad.id',
      isPremium: 'publisher.subscription.isPremium',
    }),
    environment(),
  ]
);

registerEventHandler(IMAGES_OPTIMIZATION_FAILED, (_, [{ error }, { field }]) =>
  state.set({ [`imageUploader.${field}.error`]: { id: error.message } })
);

registerEventHandler(
  IMAGES_UPLOADED,
  (
    {
      state: {
        existingImages,
        adId,
        hasPremiumSubscription,
        sortingWithAi,
        aiSorted,
      },
      environment: { apiUrl },
    },
    [{ data }]
  ) => {
    const updatedImages = appendNewImages(data.images, existingImages);
    const updatedImagesWithAi = appendNewImages(data.images, aiSorted);
    const useAiSorting =
      hasPremiumSubscription && (sortingWithAi || sortingWithAi == null);
    const mutations = {
      'adForm:images': {
        uploaded: updatedImages,
      },
      'adForm:ad.propertyImagesSortedWithAi': useAiSorting,
      'adForm:ad.propertyImages': useAiSorting
        ? updatedImagesWithAi
        : updatedImages,
    };

    if (!hasPremiumSubscription) {
      return state.set(mutations);
    }
    let imagesProgressStatus = {
      ...effects.dispatchLater({
        id: IMAGES_STATUS_UPLOADED,
        milliseconds: 2000,
      }),
    };
    let eventHttp = {};
    if (useAiSorting && updatedImages.length > MIN_IMAGES_TO_SORT_WITH_AI) {
      imagesProgressStatus = {
        ...effects.dispatchLater({
          id: IMAGES_PROGRESS_STATUS_THINKING,
          milliseconds: 500,
        }),
      };
      eventHttp = {
        ...http.post({
          url: `${apiUrl}/images-scoring/sort/${adId}`,
          body: {
            images: updatedImages.map((image) => image.file),
          },
          successEvent: IMAGES_AI_SORTED,
          errorEvent: IMAGES_AI_SORTED_FAILED,
        }),
      };
    }

    return {
      ...state.set(mutations),
      ...imagesProgressStatus,
      ...eventHttp,
    };
  },
  [
    state.get({
      existingImages: 'adForm:images.uploaded',
      adId: 'adForm:ad.id',
      hasPremiumSubscription: 'publisher.subscription.isPremium',
      sortingWithAi: 'adForm:ad.propertyImagesSortedWithAi',
      aiSorted: 'adForm:images.aiSorted',
    }),
    environment(),
  ]
);

registerEventHandler(
  IMAGES_AI_SORTED,
  ({ state: { sortingWithAi } }, [{ data }]) => {
    const images = createAiAdImages(data);
    const mutations = {
      'adForm:images.aiSorted': images,
    };
    if (sortingWithAi) {
      mutations['adForm:ad.propertyImages'] = images;
      return {
        ...state.set(mutations),
        ...effects.dispatchLater({
          id: IMAGES_PROGRESS_STATUS_THINKING_FINISH,
          milliseconds: 1500,
        }),
      };
    }

    return {
      ...state.set(mutations),
      ...effects.dispatch(IMAGES_STATUS_RESET),
    };
  },
  [
    state.get({
      sortingWithAi: 'adForm:ad.propertyImagesSortedWithAi',
    }),
  ]
);

registerEventHandler(
  IMAGES_AI_SORTED_FAILED,
  ({ state: { adId, section, sortingRetries, sortingRetryDelay } }) => {
    const retryCount = (sortingRetries || 0) + 1;
    const retryDelay =
      RETRY_DELAYS[Math.min(retryCount - 1, RETRY_DELAYS.length - 1)];
    if (sortingRetryDelay > 0) {
      return {
        ...effects.dispatch(IMAGES_PROGRESS_STATUS_ERROR),
        ...tracking.track('ImageSortingWithAiFailed', section, { adId }),
      };
    }

    return {
      ...tracking.track('ImageSortingWithAiFailed', section, { adId }),
      ...effects.dispatch(IMAGES_PROGRESS_STATUS_ERROR),
      ...effects.dispatchLater({ id: CHECK_RETRY, milliseconds: 1000 }),
      ...state.set({
        'adForm:sortingErrorRetries': retryCount,
        'adForm:sortingErrorRetryDelay': retryDelay,
      }),
    };
  },
  [
    state.get({
      adId: 'adForm:ad.id',
      section: 'adForm:form',
      sortingRetries: 'adForm:sortingErrorRetries',
      sortingRetryDelay: 'adForm:sortingErrorRetryDelay',
    }),
  ]
);

registerEventHandler(
  CHECK_RETRY,
  ({ state: { seconds } }) =>
    seconds === 0
      ? {}
      : {
          ...state.set({
            'adForm:sortingErrorRetryDelay': seconds - 1,
          }),
          ...effects.dispatchLater({ id: CHECK_RETRY, milliseconds: 1000 }),
        },
  [state.get({ seconds: 'adForm:sortingErrorRetryDelay' })]
);

registerEventHandler(
  RETRY_SORTING_WITH_AI,
  ({ state: { adId, section } }) => ({
    ...effects.dispatch({
      id: TOGGLE_SORTING_MODE,
      payload: { usingAI: true },
    }),
    ...tracking.track('ImageSortingWithAiRetried', section, { adId }),
  }),
  [state.get({ adId: 'adForm:ad.id', section: 'adForm:form' })]
);

registerEventHandler(
  IMAGES_SORTED,
  (
    { state: { propertyImagesSortedWithAi } },
    { field, images, draggedImageId, destinationImageId }
  ) => {
    const draggedImageIndex = imageIndex(images, draggedImageId);
    const destinationImageIndex = imageIndex(images, destinationImageId);

    if (draggedImageIndex < 0 || destinationImageIndex < 0) {
      return {};
    }
    const hasMainImage = fieldHasMainImage(field);

    if (hasMainImage) {
      const firstImageIndex = 0;
      const secondImageIndex = 1;

      if (destinationImageIndex === firstImageIndex) {
        images[destinationImageIndex].isMain = false;
        images[draggedImageIndex].isMain = true;
      }
      if (draggedImageIndex === firstImageIndex) {
        images[draggedImageIndex].isMain = false;
        images[secondImageIndex].isMain = true;
      }
    }

    const newImages = arrayMove(
      images,
      draggedImageIndex,
      destinationImageIndex
    );
    const mutations = {
      [`adForm:ad.${field}`]: newImages,
      'adForm:showRestoreSortButton': propertyImagesSortedWithAi,
    };
    if (!propertyImagesSortedWithAi) {
      mutations[`adForm:images.uploaded`] = newImages;
    }
    return state.set(mutations);
  },
  [
    state.get({
      propertyImagesSortedWithAi: 'adForm:ad.propertyImagesSortedWithAi',
    }),
  ]
);

registerEventHandler(IMAGE_DELETED, (_, { field, imageId }) =>
  state.set({ [`imageUploader.${field}.imageIdToDelete`]: imageId })
);

registerEventHandler(
  IMAGE_DELETE_CONFIRMED,
  (
    { state: { imageUploader, images: propertyImages, isDeveloper } },
    { field, images }
  ) => {
    const idToDelete = imageUploader[field].imageIdToDelete;
    const indexInAd = imageIndex(images, idToDelete);
    const url = imageUrl(images, idToDelete);

    if (indexInAd < 0) {
      return {};
    }

    const hasMainImage = fieldHasMainImage(field);
    const removeImageFrom = (imgs) => {
      if (imgs == null) {
        return [];
      }

      const newImages = removeImageByUrl(imgs, url);
      return hasMainImage ? assignMainImageWhenUndefined(newImages) : newImages;
    };
    const mutations = {
      [`adForm:ad.${field}`]: removeImageFrom(images),
      [`imageUploader.${field}.imageIdToDelete`]: undefined,
      [`imageUploader.${field}.error`]: undefined,
    };
    if (field === 'propertyImages' && !isDeveloper) {
      mutations['adForm:images.uploaded'] = removeImageFrom(
        propertyImages.uploaded
      );
      mutations['adForm:images.aiSorted'] = removeImageFrom(
        propertyImages.aiSorted
      );
    }
    return state.set(mutations);
  },
  [
    state.get({
      imageUploader: 'imageUploader',
      images: 'adForm:images',
      isDeveloper: 'publisher.isDeveloper',
    }),
  ]
);

registerEventHandler(IMAGE_DELETE_CANCELED, (_, { field }) =>
  state.set({ [`imageUploader.${field}.imageIdToDelete`]: undefined })
);
registerEventHandler(IMAGES_PROGRESS_STATUS_UPLOADING, (_) =>
  state.set({
    imageUploaderStatus: UPLOAD_ANIMATION_STATUS.LOADING,
    'adform:ad.showThinking': false,
  })
);
registerEventHandler(IMAGES_STATUS_UPLOADED, (_) => ({
  ...state.set({
    imageUploaderStatus: UPLOAD_ANIMATION_STATUS.FINISH,
    'adform:ad.showThinking': false,
  }),
  ...effects.dispatchLater({
    id: IMAGES_STATUS_RESET,
    milliseconds: 2000,
  }),
}));

registerEventHandler(IMAGES_PROGRESS_STATUS_THINKING, (_) => ({
  ...state.set({
    imageUploaderStatus: UPLOAD_ANIMATION_STATUS.THINKING,
    'adform:ad.showThinking': false,
  }),
  ...effects.dispatchLater({
    id: IMAGES_PROGRESS_STATUS_SHOW_THINKING,
    milliseconds: 1000,
  }),
}));

registerEventHandler(IMAGES_PROGRESS_STATUS_SHOW_THINKING, (_) =>
  state.set({ 'adform:ad.showThinking': true })
);

registerEventHandler(IMAGES_PROGRESS_STATUS_ONLY_THINKING, (_) =>
  state.set({
    imageUploaderStatus: UPLOAD_ANIMATION_STATUS.ONLY_THINKING,
    'adform:ad.showThinking': false,
  })
);

registerEventHandler(IMAGES_PROGRESS_STATUS_THINKING_FINISH, (_) =>
  state.set({
    imageUploaderStatus: UPLOAD_ANIMATION_STATUS.THINKING_FINISH,
    'adform:ad.showThinking': false,
  })
);
registerEventHandler(IMAGES_PROGRESS_STATUS_ERROR, (_) =>
  state.set({
    imageUploaderStatus: UPLOAD_ANIMATION_STATUS.ERROR,
    'adform:ad.showThinking': false,
  })
);

registerEventHandler(IMAGES_STATUS_RESET, (_) =>
  state.set({ imageUploaderStatus: null, 'adform:ad.showThinking': false })
);

registerEventHandler(IMAGES_PROGRESS_STATUS_UPLOAD_FAILED, () => {});

registerEventHandler(
  TOGGLE_SORTING_MODE,
  (
    {
      environment: { apiUrl },
      state: { adId, backupAiSorted, backupUploaded, propertyImages },
    },
    { usingAI }
  ) => {
    if (!usingAI) {
      return {
        ...state.set({
          'adForm:ad.propertyImagesSortedWithAi': false,
          'adForm:showRestoreSortButton': false,
          'adForm:ad.propertyImages': backupUploaded,
        }),
        ...effects.dispatch(IMAGES_STATUS_RESET),
      };
    }
    const mutations = {
      'adForm:ad.propertyImagesSortedWithAi': true,
      'adForm:images.uploaded': propertyImages,
    };

    const aiImagesNotLoaded =
      backupAiSorted == null ||
      !Array.isArray(backupAiSorted) ||
      backupAiSorted.length === 0 ||
      backupAiSorted.length !== propertyImages.length;
    if (
      aiImagesNotLoaded &&
      propertyImages != null &&
      propertyImages.length > 0
    ) {
      if (propertyImages.length <= MIN_IMAGES_TO_SORT_WITH_AI) {
        mutations['adForm:ad.propertyImages'] = propertyImages;
        // Here there is a bug when delete images and then try to sort with AI
        return state.set(mutations);
      }
      return {
        ...state.set(mutations),
        ...effects.dispatch(IMAGES_PROGRESS_STATUS_ONLY_THINKING),
        ...http.post({
          url: `${apiUrl}/images-scoring/sort/${adId}`,
          body: {
            images: propertyImages.map((x) => x.file),
          },
          successEvent: IMAGES_AI_SORTED,
          errorEvent: IMAGES_AI_SORTED_FAILED,
        }),
      };
    }

    mutations['adForm:ad.propertyImages'] = backupAiSorted;
    return {
      ...state.set(mutations),
      ...effects.dispatch(IMAGES_PROGRESS_STATUS_THINKING_FINISH),
    };
  },
  [
    environment(),
    state.get({
      adId: 'adForm:ad.id',
      backupAiSorted: 'adForm:images.aiSorted',
      backupUploaded: 'adForm:images.uploaded',
      propertyImages: 'adForm:ad.propertyImages',
    }),
  ]
);

registerEventHandler(
  RESTORE_SORTING_WITH_AI,
  ({ state: { backupAiSorted } }) =>
    state.set({
      'adForm:ad.propertyImagesSortedWithAi': true,
      'adForm:showRestoreSortButton': false,
      'adForm:ad.propertyImages': backupAiSorted,
    }),
  [
    state.get({
      backupAiSorted: 'adForm:images.aiSorted',
    }),
  ]
);

function mergePreviousImagesWithOptimized(adImages, optimizedImages) {
  return [
    ...adImages,
    ...createOptimizedImagesObjects(adImages, optimizedImages),
  ];
}

function createOptimizedImagesObjects(adImages, optimizedImages) {
  const maxId = adImages.reduce(
    (acc, current) => (current.id > acc ? current.id : acc),
    0
  );
  return optimizedImages.map((file, idx) =>
    createImage({ id: maxId + idx + 1, file })
  );
}

function imageIndex(images, id) {
  return images.findIndex((img) => img.id === id);
}

function imageUrl(images, id) {
  return images.find((img) => img.id === id).file;
}
