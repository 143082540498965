import styled from 'styled-components';
import { useSelector } from 'reffects-store';
import { dispatch } from 'reffects';
import { Link } from 'react-router-dom';
import Avatar from 'design-system/components/Avatar/Avatar';
import DropdownMenu from 'design-system/components/DropdownMenu';
import Menu from 'design-system/components/Menu/Menu';
import { spacing, SPACING_SIZE } from 'design-system/styles/spacing';
import {
  FONT_SIZE,
  FONT_WEIGHT,
  typography,
} from 'design-system/styles/typography';
import { Body1 } from 'design-system/components/Typography/presets/Body1';
import {
  isPremiumSubscriptionSelector,
  publisherLogoSelector,
  publisherNameSelector,
} from '../../../../selectors/publisher';
import FormattedMessageWithValidation from '../../../../partials/FormattedMessageWithValidation/FormattedMessageWithValidation';
import { changePasswordRoute } from '../../../../utils/proppitWebRouter';
import {
  LOGOUT_USER_REQUESTED,
  UPDATE_USER_LOCALE,
} from '../../../../App/events';
import {
  localeSelector,
  userEmailSelector,
  userIsSuperadminSelector,
} from '../../../../selectors/user';
import { ProtectedComponent } from '../../../ProtectedComponent';
import { useFeatureFlagSelector } from '../../../../utils/featuresFlags/selectors';

const Wrapper = styled.div`
  ${spacing.value(SPACING_SIZE.L)}
`;

const AvatarButton = styled.button`
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  gap: ${spacing.value(SPACING_SIZE.M)};
`;

const PublisherInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  ${typography.size(FONT_SIZE.M)}
`;

const PublisherName = styled.span`
  ${typography.weight(FONT_WEIGHT.MEDIUM)}
`;

const BodyItem = styled(Body1)`
  line-height: 24px;
`;

function HeaderProfileLink() {
  const CARING_CENTRALIZE_PERMISSIONS = useFeatureFlagSelector(
    'CARING_CENTRALIZE_PERMISSIONS'
  );
  const publisherLogoSource = useSelector(publisherLogoSelector);
  const currentLocale = useSelector(localeSelector);
  const showChangeLocale = useSelector(userIsSuperadminSelector);
  const publisherName = useSelector(publisherNameSelector);
  const userEmail = useSelector(userEmailSelector);
  const handleMenuItemConfirmed = ({ value }) =>
    value && dispatch({ id: value.eventId, payload: value.payload });
  const hasPremiumSubscription = useSelector(isPremiumSubscriptionSelector);

    if (CARING_CENTRALIZE_PERMISSIONS) {
        return (
            <Wrapper>
                <DropdownMenu
                    button={
                        <AvatarButton>
                            <Avatar
                                source={publisherLogoSource}
                                hasPremiumSubscription={hasPremiumSubscription} />
                            <PublisherInfo>
                                <PublisherName>{publisherName}</PublisherName>
                                <span>{userEmail}</span>
                            </PublisherInfo>
                        </AvatarButton>
                    }
                    onConfirm={handleMenuItemConfirmed}
                >
                    <Menu>
                        <Menu.Item
                            id="user_menu_change_password"
                            label={
                                <BodyItem $noWrap>
                                    <FormattedMessageWithValidation id="user_menu_changepwd" />
                                </BodyItem>
                            }
                            forwardedAs={Link}
                            to={changePasswordRoute()}
                        />
                        {currentLocale === 'th-TH' && (
                            <ProtectedComponent action="user/change-locale">
                                <Menu.Item
                                    id="user_menu_change_to_english"
                                    label={<BodyItem $noWrap>Change to english</BodyItem>}
                                    forwardedAs={Link}
                                    value={{ eventId: UPDATE_USER_LOCALE, payload: 'en-US' }}
                                />
                            </ProtectedComponent>
                        )}
                        {currentLocale === 'en-US' && (
                            <ProtectedComponent action="user/change-locale">
                                <Menu.Item
                                    id="user_menu_change_to_thai"
                                    label={<BodyItem $noWrap>Change to thai</BodyItem>}
                                    forwardedAs={Link}
                                    value={{ eventId: UPDATE_USER_LOCALE, payload: 'th-TH' }}
                                />
                            </ProtectedComponent>
                        )}
                        <Menu.Item
                            id="user_menu_logout"
                            label={
                                <BodyItem $noWrap>
                                    <FormattedMessageWithValidation id="user_menu_logout" />
                                </BodyItem>
                            }
                            value={{ eventId: LOGOUT_USER_REQUESTED }}
                            tabIndex={0}
                            role="button"
                        />
                    </Menu>
                </DropdownMenu>
            </Wrapper>
        );
    }

  return (
    <Wrapper>
      <DropdownMenu
        button={
          <AvatarButton>
            <Avatar
              source={publisherLogoSource}
              hasPremiumSubscription={hasPremiumSubscription}
            />
            <PublisherInfo>
              <PublisherName>{publisherName}</PublisherName>
              <span>{userEmail}</span>
            </PublisherInfo>
          </AvatarButton>
        }
        onConfirm={handleMenuItemConfirmed}
      >
        <Menu>
          <Menu.Item
            id="user_menu_change_password"
            label={
              <BodyItem $noWrap>
                <FormattedMessageWithValidation id="user_menu_changepwd" />
              </BodyItem>
            }
            forwardedAs={Link}
            to={changePasswordRoute()}
          />
          {currentLocale === 'th-TH' && showChangeLocale && (
            <Menu.Item
              id="user_menu_change_to_english"
              label={<BodyItem $noWrap>Change to english</BodyItem>}
              forwardedAs={Link}
              value={{ eventId: UPDATE_USER_LOCALE, payload: 'en-US' }}
            />
          )}
          {currentLocale === 'en-US' && showChangeLocale && (
            <Menu.Item
              id="user_menu_change_to_thai"
              label={<BodyItem $noWrap>Change to thai</BodyItem>}
              forwardedAs={Link}
              value={{ eventId: UPDATE_USER_LOCALE, payload: 'th-TH' }}
            />
          )}
          <Menu.Item
            id="user_menu_logout"
            label={
              <BodyItem $noWrap>
                <FormattedMessageWithValidation id="user_menu_logout" />
              </BodyItem>
            }
            value={{ eventId: LOGOUT_USER_REQUESTED }}
            tabIndex={0}
            role="button"
          />
        </Menu>
      </DropdownMenu>
    </Wrapper>
  );
}

export default HeaderProfileLink;
